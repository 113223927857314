<template>
  <div id="standalone-places-searchbox-container" class="container">
    <div ref="domNode" class="places-searchbox">
      <label for="search-field" class="label">
        <span class="accessibility">{{
          useMicroCopy("servicelocator.location.label")
        }}</span>
        <SvgIcon name="func_search" class="icon-search" />
      </label>
      <input
        ref="buttonNode"
        v-model="query"
        tabindex="0"
        type="text"
        :placeholder="useMicroCopy('servicelocator.placeholder')"
        name="search-field"
        class="search-field"
        aria-haspopup="true"
        aria-controls="menu2"
      />
      <SimpleButton
        class="search-button"
        solid
        @click="query?.length ? handleSearch() : ''"
      >
        <span class="text">{{
          useMicroCopy("servicelocator.searchcta.label")
        }}</span>
        <SvgIcon name="func_search" class="icon-search" />
      </SimpleButton>

      <ul
        v-show="showSuggestions"
        id="menu2"
        ref="menuNode"
        class="suggestions"
        role="menu"
        aria-labelledby="menubutton"
      >
        <li v-for="item in suggestions" :key="item.place_id" class="suggestion">
          <button role="menuitem">
            <span class="main">{{ item.structured_formatting.main_text }}</span>
            <span class="secondary text-70">{{
              item.structured_formatting.secondary_text
            }}</span>
          </button>
        </li>
        <li class="geoloc">
          <button role="menuitem">
            <SvgIcon name="func_focus" />
            {{ useMicroCopy("servicelocator.usecurrentlocation") }}
          </button>
        </li>
      </ul>
    </div>
    <div class="filters">
      <div
        v-for="filter in allowedFacilityTypeGroupKeysList"
        :key="filter"
        class="filter"
      >
        <FormCheckbox
          v-model="filter.isIncluded"
          :name="filter.name"
          :label="
            useMicroCopy(
              `servicelocator.searchengine.label.${filter.name?.replace('-', '')}`
            )
          "
          @change="
            trackingStore.updateKeywords(query);
            updateFilters($event, filter.name);
          "
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { usePlacesAutocomplete, getGeocode } from "vue-use-places-autocomplete";
import { useGeolocation } from "@vueuse/core";
import { useSnakeCaseFilter } from "@/composables/SnakecaseFilter";
import { navigateTo } from "nuxt/app";
import { useTrackingStore } from "@/stores/tracking";

const config = useRuntimeConfig();
const trackingStore = useTrackingStore();

const props = defineProps({
  location: {
    type: String,
    default: "",
  },
  includeFacilityTypeGroupKey: {
    type: String,
    required: false,
    default: "",
  },
});
const allowedFacilityTypeGroupKeysList = await GetFacilityTypeGroupKeys(
  props.includeFacilityTypeGroupKey,
  null
);

const updateFilters = (event, name) => {
  const filter = allowedFacilityTypeGroupKeysList?.find(
    (item) => item.name === name
  );
  filter.isIncluded = event.target.checked;
};

// Google Places start
const query = ref("");

const { suggestions } = usePlacesAutocomplete(query, {
  apiKey: config.public["gmapsApiKey"],
  debounce: 500,
  minLengthAutocomplete: 3,
  autocompletionRequest: {
    componentRestrictions: {
      country: config.public["country"],
    },
  },
});

const currentLocation = ref(null);
const { coords } = await useGeolocation();

watch(coords, async (newCoords, oldCoords) => {
  if (newCoords.latitude !== Infinity && newCoords.latitude !== null) {
    const curr = await getGeocode({
      location: {
        lat: newCoords.latitude,
        lng: newCoords.longitude,
      },
    });
    currentLocation.value = curr[curr.length - 4].formatted_address;
  }
});

const searchWithCurrentLocation = () => {
  query.value = currentLocation.value;
};
// Google places end

// Search Facilities start
const chosenPlaceId = ref("");

const getDetails = async (placeId = chosenPlaceId.value) => {
  chosenPlaceId.value = placeId;

  const details = await getGeocode({ placeId: placeId });
  query.value = details[0].formatted_address;

  return details;
};
// Search Facilities end

// setup
const domNode = ref();
const buttonNode = ref();
const menuNode = ref();
const menuitemNodes = ref([]);

// methods
const updateMenuitemNodes = () => {
  menuitemNodes.value = [];
  const nodes = domNode.value?.querySelectorAll('[role="menuitem"]');

  for (let i = 0; i < nodes?.length; i++) {
    const menuitem = nodes[i];
    menuitemNodes.value.push(menuitem);
    if (menuitem.textContent.trim().length > 0) {
      trackingStore.updateKeywords(suggestions?.value?.[0]?.description);
    }
  }
};

const onSelectMenuitem = async (_, index) => {
  if (index >= suggestions.value.length) {
    searchWithCurrentLocation();
  } else {
    const item = suggestions.value[index];

    const data = await getDetails(item?.place_id);

    trackingStore.sendTrackingData({
      event: "click.navigation",
      click: "search",
      click_chapter1: "hero",
      click_chapter2: "search_bar",
      ise_keyword: useSnakeCaseFilter(item?.description),
    });
    return !!data;
  }
};

const { showDropdown: showSuggestions } = useDropdownNavigation(
  buttonNode,
  menuitemNodes,
  domNode,
  onSelectMenuitem
);

watch(
  suggestions,
  () => {
    updateMenuitemNodes();
  },
  { immediate: true, flush: "post" }
);

const handleSearch = () => {
  setTimeout(async () => {
    if (suggestions.value?.length) {
      trackingStore.updateKeywords(suggestions.value?.[0]?.description || "");
      trackingStore.sendTrackingData({
        event: "click.navigation",
        click: "search",
        click_chapter1: useSnakeCaseFilter(props.location || ""),
        click_chapter2: "search_bar",
        ise_keyword: useSnakeCaseFilter(trackingStore.getKeywords),
      });
      chosenPlaceId.value = chosenPlaceId.value?.length
        ? chosenPlaceId.value
        : suggestions.value[0].place_id;

      const locator = await useCustomAsyncStoryblok("", {
        version: config.public["storyblokVersion"],
        content_type: "page-locator",
        starts_with: config.public["storyblokCountryPrefix"],
      })
        .then((response) => {
          if (response.value.stories.length > 0) {
            return strippedURL(response.value.stories[0].full_slug);
          }
          return "/";
        })
        .catch((error) => {
          console.log(error); // eslint-disable-line
        });

      const includedFilters = allowedFacilityTypeGroupKeysList?.length
        ? allowedFacilityTypeGroupKeysList
            ?.filter((item) => item.isIncluded)
            .map((item) => item.name)
            .join("|")
        : "";
      const serviceLocatorURL = `${locator}?placeid=${chosenPlaceId.value}&includedFilters=${includedFilters}`;
      navigateTo(serviceLocatorURL);
    }
  });
};
</script>

<style lang="scss" scoped>
.places-searchbox {
  z-index: 1;
  padding: 0.25rem 0.25rem 0.25rem 1.12rem;
  @include pair-7;
  border-radius: $radius-m;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  height: 3.75rem;

  @include for-tablet-landscape-up {
    padding-left: 1.5rem;
    border-radius: $radius-l;
    height: 5rem;
  }

  .label {
    display: block;
    @include size-2xs;
    .icon-search {
      width: 1.5rem;
      height: auto;
      margin-top: 0.2rem;
      @include for-phone-only {
        display: none;
      }
    }
  }

  .search-field {
    appearance: none;
    border: none;
    margin: 0;
    display: block;
    padding: 0;
    height: 100%;
    width: 100%;
    @include size-m;

    @include for-tablet-landscape-up {
      @include size-s;
    }
  }
}

.suggestions {
  border-radius: $radius-s;
  position: absolute;
  z-index: 3;
  top: calc(100% + 1rem);
  left: 0;
  width: 90vw;
  box-shadow: 0 0.25rem 1rem 0 var(--text-50);
  overflow: hidden;

  @include for-tablet-landscape-up {
    width: 40vw;
  }

  button {
    display: block;
    width: 100%;
    text-align: left;
    appearance: none;
    border: none;
    border-radius: 0;
    background: var(--solid-07);
    padding: 1.2rem;
    cursor: pointer;

    @include for-tablet-landscape-up {
      padding: 1rem 2.25rem;
    }

    &:focus,
    &:hover {
      @include pair-2;
      font-weight: bold;
    }
  }
}

.suggestion {
  span {
    display: block;

    &.main {
      @include size-s;
    }

    &.secondary {
      @include size-2xs;
    }
  }
}
.geoloc {
  button {
    @include size-s;
    font-weight: bold;
    display: flex;
    gap: 0.5rem;
  }
}

:deep(.simple-button) {
  color: var(--solid-07) !important;
  border-radius: $radius-m !important;
  height: 100%;
  @include for-tablet-landscape-up {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .icon-search {
    width: 1.25rem !important;
    height: auto !important;
  }
}

.suggestion + .geoloc {
  border-top: $border-grey;
}

.filters {
  display: flex;
  gap: 0.75rem 1.25rem;
  flex-wrap: wrap;
  padding-top: 1.5rem;
  &.hidden {
    display: none;
  }
}

.filter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  width: calc(50% - 1.25rem);

  @include for-tablet-portrait-up {
    width: auto;
  }
}

.search-button {
  .text {
    display: none;
  }
  @include for-tablet-portrait-up {
    .text {
      display: inline-block;
    }
    .icon-search {
      display: none;
    }
  }
}

.checkbox :deep(input[type="checkbox"]) {
  @include for-desktop-down {
    width: 1.25rem !important;
    height: 1.25rem !important;
  }
}
</style>
